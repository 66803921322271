import { forwardRef, KeyboardEvent, useCallback, useMemo } from 'react';
import { FormApi } from 'final-form';
import { makeStyles, createStyles, Theme, Paper, Portal } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';

import { SaleContractFields, LeaseContractFields, ComponentsFields } from './fieldGroups';
import { FormRenderProps, useFormState } from 'react-final-form';
import {
  ApplicationRole,
  CalculationMethod,
  CalculationResult,
  Currency,
  QuotaNomenclatureViewModel,
  TemplateKind,
  ValidationProblemDetails,
} from 'schema';
import { CalculationFormValues } from '../types';
import {
  CalculationResults,
  AnnuityPaymentSchedule,
  StraightLinePaymentSchedule,
  SeasonalPaymentSchedule,
} from './results';
import { AutoFocusedForm, Button, ModalForm, Role, useModalForm, WarningTooltip } from 'components';
import { CurrencyRatesInfo } from './CurrencyRatesInfo';
import { CalculatedFields } from './CalculatedFields';
import { PaymentScheduleAccordion } from './PaymentScheduleAccordion';
import { CalculationFormApiContextProvider } from './CalculationFormApiContextProvider';
import { useTranslation } from 'react-i18next';
import { formatCurrency, formatNumber } from 'components/utils';
import { PrintableTemplateList } from 'components/templates/PrintableTemplatesList';
import { ApplicationRoles } from 'services/authentication/ApplicationRoles';
import { NomenclaturesRequestForm } from './NomenclaturesRequestForm';

type CalculationProps = {
  error?: ValidationProblemDetails | null;
  isLoading?: boolean;
  data?: CalculationResult;
  copyEnabled?: boolean;
  drawUpEnabled?: boolean;
  quotaId?: number;
  isLocked?: boolean;
  isTask?: boolean;
  sendToIsEnabled?: boolean;
  approvalEnabled?: boolean;
  issueId?: number;
};

type CalculationInnerFormProps = FormRenderProps<
  CalculationFormValues,
  Partial<CalculationFormValues>
> &
  CalculationProps;

type CalculationFormProps = CalculationProps & {
  onSubmit: (
    values: CalculationFormValues,
    form: FormApi<CalculationFormValues, Partial<CalculationFormValues>>
  ) => Promise<void>;
  initialValues: CalculationFormValues;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    panels: {
      marginBottom: (props: CalculationProps) => (props.isTask ? '0' : '75px'),
    },
    actions: {
      [theme.breakpoints.down(1200)]: {
        width: 'calc(100% - 85px)',
        left: 63,
      },
      display: 'flex',
      justifyContent: 'flex-start',
      width: 'calc(100% - 238px)',
      padding: theme.spacing('20px', '20px'),
      bottom: 0,
      left: 216,
      position: 'fixed',
      zIndex: 1,
    },
    actionsTask: {
      display: 'flex',
      justifyContent: 'flex-start',
      width: '100%',
      padding: theme.spacing('20px', '20px'),
      bottom: 0,
      position: 'static',
      zIndex: 1,
      marginBottom: theme.spacing(2),
    },
    actionButton: {
      marginRight: theme.spacing(2.5),
    },
    warningIcon: {
      cursor: 'pointer',
      '&$warningIcon svg': {
        fill: theme.palette.darkAttention.main,
        cursor: 'pointer',
        pointerEvents: 'auto',
      },
    },
    disabledAccordionTitle: {
      color: theme.palette.secondary.dark,
    },
    nbvTitle: {
      '&&': {
        lineHeight: '14px',
        fontSize: 10,
      },
    },
  })
);

const CalculationFormInner = forwardRef<HTMLDivElement, CalculationInnerFormProps>(
  (props: CalculationInnerFormProps, ref) => {
    const classes = useStyles(props);
    const { t } = useTranslation();
    const {
      handleSubmit: submit,
      isLocked = false,
      isLoading,
      data,
      form,
      submitting,
      copyEnabled = false,
      drawUpEnabled = false,
      isTask = false,
      quotaId,
    } = props;

    const change = form.change;
    const {
      values: { nomenclatures },
    } = useFormState();

    const newNomenclatures = useMemo(() => {
      return nomenclatures
        ? nomenclatures.filter((item: QuotaNomenclatureViewModel) => !item?.code)
        : [];
    }, [nomenclatures]);

    const {
      onOpen: onOpenNomenclatures,
      onClose: onCloseNomenclatures,
      open: openNomenclatures,
    } = useModalForm();

    const handleOnCalculate = useCallback(
      (event) => {
        change('save', false);
        submit(event);
      },
      [change, submit]
    );

    const handleOnSave = useCallback(
      (event) => {
        change('save', true);
        submit(event);
      },
      [change, submit]
    );

    const handleOnCopy = useCallback(
      (event) => {
        if (copyEnabled) {
          change('copy', true);
          submit(event);
        }
      },
      [copyEnabled, change, submit]
    );

    const handleKeyDown = (ev: KeyboardEvent) => {
      if (ev.key === 'Enter') {
        handleOnCalculate(ev);
      }
    };

    return (
      <>
        <form onSubmit={submit} onKeyDown={handleKeyDown}>
          <CalculationFormApiContextProvider form={form}>
            <CalculatedFields />
          </CalculationFormApiContextProvider>
          <div className={classes.panels}>
            <Accordion defaultExpanded={true}>
              <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                <Typography variant="subtitle1">{t('ContractOfSale')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <SaleContractFields
                  form={form}
                  quotaId={quotaId}
                  onOpenNomenclatures={onOpenNomenclatures}
                />
              </AccordionDetails>
            </Accordion>
            <CurrencyRatesInfo />
            <Accordion defaultExpanded={true}>
              <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
                <Typography variant="subtitle1">{t('ContractOfLease')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <LeaseContractFields form={form} />
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={true}>
              <AccordionSummary aria-controls="panel3a-content" id="panel3a-header">
                <Typography variant="subtitle1">{t('Components')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ComponentsFields form={form} />
              </AccordionDetails>
            </Accordion>
            <PaymentScheduleAccordion />
            <Accordion disabled defaultExpanded={false} expanded={!!data}>
              <AccordionSummary ref={ref} aria-controls="panel4a-content" id="panel4a-header">
                <Typography className={classes.disabledAccordionTitle} variant="subtitle1">
                  {t('CalculationResult')}
                </Typography>
                <Typography
                  color="textPrimary"
                  variant="body1"
                  component={'span'}
                  className={classes.nbvTitle}
                >
                  &nbsp; ({t('IncludingVAT')}) &nbsp; | &nbsp; NBV&nbsp;
                </Typography>
                <Typography color="textPrimary" variant="subtitle1">
                  {data?.fundingAmountNBV && formatNumber(data?.fundingAmountNBV, 2, true)}&nbsp;
                  {data?.fundingAmountNBV && formatCurrency(Currency.Ruble)}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>{data && <CalculationResults data={data} />}</AccordionDetails>
            </Accordion>
            <Accordion disabled defaultExpanded={false} expanded={!!data}>
              <AccordionSummary aria-controls="panel5a-content" id="panel5a-header">
                <Typography className={classes.disabledAccordionTitle} variant="subtitle1">
                  {t('ScheduleOfPayments')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {data?.calculationMethod === CalculationMethod.Annuity && (
                  <AnnuityPaymentSchedule {...data} />
                )}
                {data?.calculationMethod === CalculationMethod.StraightLine && (
                  <StraightLinePaymentSchedule {...data} />
                )}
                {data?.calculationMethod === CalculationMethod.Seasonal && (
                  <SeasonalPaymentSchedule {...data} />
                )}
                {data?.calculationMethod === CalculationMethod.SuperSeasonal && (
                  <SeasonalPaymentSchedule {...data} />
                )}
              </AccordionDetails>
            </Accordion>
            {quotaId && <PrintableTemplateList quotaId={quotaId} kind={TemplateKind.Calculation} />}
          </div>
          {isTask ? (
            <Paper square className={classes.actionsTask}>
              <div className={classes.actionButton}>
                <Button
                  color="primary"
                  size="medium"
                  type="submit"
                  variant="contained"
                  disabled={isLoading || submitting}
                  onClick={(event) => handleOnCalculate(event)}
                >
                  {t('Calculate')}
                </Button>
              </div>
              <Role role={ApplicationRoles.allExceptAssetManager}>
                <div className={classes.actionButton}>
                  <Button
                    color="primary"
                    size="medium"
                    type="button"
                    variant="outlined"
                    disabled={isLoading || submitting || isLocked}
                    onClick={(event) => handleOnSave(event)}
                  >
                    {t('Save')}
                  </Button>
                </div>
                {copyEnabled && (
                  <div className={classes.actionButton}>
                    <Button
                      color="primary"
                      size="medium"
                      type="button"
                      variant="outlined"
                      disabled={isLoading || submitting}
                      onClick={(event) => handleOnCopy(event)}
                    >
                      {t('SaveAsNew')}
                    </Button>
                  </div>
                )}
              </Role>
              <Role
                role={[
                  'admin',
                  'super_sales_manager',
                  'sales_manager',
                  ApplicationRole.RiskManager,
                ]}
              >
                {drawUpEnabled && !isLocked && (
                  <div className={classes.actionButton}>
                    <Button
                      color="primary"
                      size="medium"
                      type="button"
                      variant="outlined"
                      disabled={isLoading || submitting}
                      className={newNomenclatures.length > 0 ? 'Mui-disabled' : ''}
                      startIcon={
                        newNomenclatures.length > 0 && (
                          <WarningTooltip
                            arrow
                            placement="bottom-start"
                            className={classes.warningIcon}
                            onOpenNomenclatures={onOpenNomenclatures}
                            taskIds={
                              newNomenclatures.filter((item: any) => !item.taskId).length > 0
                                ? []
                                : newNomenclatures
                                    .filter((item: any) => item.taskId)
                                    .map((item: any) => item.taskId)
                            }
                          />
                        )
                      }
                      onClick={() => console.log('handleOnDrawUp')}
                    >
                      {t('Draw up contract')}
                    </Button>
                  </div>
                )}
              </Role>
            </Paper>
          ) : (
            <Portal container={document.body}>
              <Paper square className={classes.actions}>
                <div className={classes.actionButton}>
                  <Button
                    color="primary"
                    size="medium"
                    type="submit"
                    variant="contained"
                    disabled={isLoading || submitting}
                    onClick={(event) => handleOnCalculate(event)}
                  >
                    {t('Calculate')}
                  </Button>
                </div>
                <Role role={ApplicationRoles.allExceptAssetManager}>
                  <div className={classes.actionButton}>
                    <Button
                      color="primary"
                      size="medium"
                      type="button"
                      variant="outlined"
                      disabled={isLoading || submitting || isLocked}
                      onClick={(event) => handleOnSave(event)}
                    >
                      {t('Save')}
                    </Button>
                  </div>
                  {copyEnabled && (
                    <div className={classes.actionButton}>
                      <Button
                        color="primary"
                        size="medium"
                        type="button"
                        variant="outlined"
                        disabled={isLoading || submitting}
                        onClick={(event) => handleOnCopy(event)}
                      >
                        {t('SaveAsNew')}
                      </Button>
                    </div>
                  )}
                </Role>
                <Role
                  role={[
                    'admin',
                    'super_sales_manager',
                    'sales_manager',
                    ApplicationRole.RiskManager,
                  ]}
                >
                  {drawUpEnabled && !isLocked && (
                    <div className={classes.actionButton}>
                      <Button
                        color="primary"
                        size="medium"
                        type="button"
                        variant="outlined"
                        disabled={isLoading || submitting}
                        className={newNomenclatures.length > 0 ? 'Mui-disabled' : ''}
                        startIcon={
                          newNomenclatures.length > 0 && (
                            <WarningTooltip
                              arrow
                              placement="bottom-start"
                              className={classes.warningIcon}
                              onOpenNomenclatures={onOpenNomenclatures}
                              taskIds={
                                newNomenclatures.filter((item: any) => !item.taskId).length > 0
                                  ? []
                                  : newNomenclatures
                                      .filter((item: any) => item.taskId)
                                      .map((item: any) => item.taskId)
                              }
                            />
                          )
                        }
                        onClick={(event) => console.log('handleOnDrawUp')}
                      >
                        {t('Draw up contract')}
                      </Button>
                    </div>
                  )}
                </Role>
              </Paper>
            </Portal>
          )}
        </form>

        {quotaId && newNomenclatures.length > 0 && (
          <ModalForm open={openNomenclatures} onClose={onCloseNomenclatures}>
            <NomenclaturesRequestForm
              newNomenclatures={newNomenclatures.filter((item: any) => !item.taskId)}
              quotaId={quotaId}
              onClose={onCloseNomenclatures}
            />
          </ModalForm>
        )}
      </>
    );
  }
);

export const CalculationForm = forwardRef<HTMLDivElement, CalculationFormProps>(
  (props: CalculationFormProps, ref) => {
    const { onSubmit, initialValues, ...rest } = props;

    return (
      <AutoFocusedForm
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={(props) => {
          return <CalculationFormInner ref={ref} {...props} {...rest} />;
        }}
      />
    );
  }
);
