import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  FutureShipmentControlColor,
  FutureShipmentSortBy,
  FutureShipmentViewModel,
} from 'schema/serverTypes';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { IconSprite } from '../icons';
import { themeOrange as theme } from 'theme';
import { TableSortLabel } from '../data-grid/TableSortLabel';
import { formatDate, formatMoney } from '../utils';
import { User } from '../users';
import { FutureShipmentControl } from './FutureShipmentControl';
import { useState } from 'react';
import { FutureShipmentNomenclatureList } from './FutureShipmentNomenclatureList';
import dayjs from 'dayjs';

const useStyles = makeStyles((theme) =>
  createStyles({
    table: {
      backgroundColor: theme.palette.background.default,
      fontSize: 12,
      lineHeight: '16px',
      color: theme.palette.text.primary,
      display: 'grid',
      gridTemplateColumns: '124px 130px 1fr 550px 120px 40px',
    },
    th: {
      fontSize: 12,
      backgroundColor: theme.palette.common.white,
      color: theme.palette.text.secondary,
      fontWeight: 700,
      display: 'flex',
      alignItems: 'center',
      paddingTop: 6,
      paddingBottom: 6,
      paddingLeft: 6,
      paddingRight: 6,
      marginBottom: theme.spacing(1),
      '&$first': {
        paddingLeft: 20,
      },
      '&$last': {
        paddingRight: 20,
      },
    },
    first: {},
    last: {},
    right: {
      textAlign: 'right',
      justifyContent: 'flex-end',
    },
    center: {
      textAlign: 'center',
      justifyContent: 'center',
    },
    contractNumber: {
      paddingLeft: 20,
      paddingRight: 6,
      backgroundColor: theme.palette.common.white,
    },
    td: {
      backgroundColor: theme.palette.common.white,
      paddingLeft: 6,
      paddingRight: 6,
      '& > a': {
        color: theme.palette.text.primary,
        '&$orangeLink': {
          color: theme.palette.primary.main,
        },
        '&:hover': {
          textDecoration: 'underline',
          color: theme.palette.primary.main,
        },
      },
    },
    orangeLink: {},
    title: {
      color: theme.palette.text.secondary,
      lineHeight: '16px',
    },
    value: {
      lineHeight: '16px',
    },
    rowWrapper: {
      display: 'contents',
      '&:hover > div': {
        cursor: 'pointer',
      },
      '& > div': {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        marginBottom: (show) => (!show ? theme.spacing(1) : 0),
        borderBottom: (show) =>
          !show ? `2px solid ${theme.palette.secondary.main}` : 'transparent',
        '&$nomenclatures': {
          marginBottom: theme.spacing(1),
        },
      },
      '& > div:last-child': {
        borderBottom: `2px solid ${theme.palette.secondary.main}`,
        '&$nomenclatures': {
          paddingTop: 0,
        },
      },
      '&$allHaveShipmentDate > div': {
        backgroundColor: '#FAFAFA',
      },
    },
    allHaveShipmentDate: {},
    nomenclatures: {
      gridColumn: '1 / 7',
      paddingLeft: 16,
      paddingRight: 16,
    },
    shipmentControls: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridGap: theme.spacing(0.5, 1),
      alignItems: 'start',
    },
    nomenclaturesSwitcher: {
      cursor: 'pointer',
    },
    orangeDate: {
      color: theme.palette.primary.main,
    },
  })
);

type FutureShipmentTableProps = {
  rows: FutureShipmentViewModel[];
  isLoading: boolean;
  sorting: any;
};

export const FutureShipmentTable = (props: FutureShipmentTableProps) => {
  const { rows, sorting } = props;

  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <div className={classes.table}>
        <div className={clsx(classes.th, classes.first)}>
          <TableSortLabel columnName={FutureShipmentSortBy.expectedShipmentDate} sorting={sorting}>
            {t('Expected shipping date')}
          </TableSortLabel>
        </div>
        <div className={clsx(classes.th, classes.right)}>{t('Contract')}</div>
        <div className={clsx(classes.th)} />
        <div className={clsx(classes.th)}>
          <TableSortLabel columnName={FutureShipmentSortBy.controls} sorting={sorting}>
            {t('Controls')}
          </TableSortLabel>
        </div>
        <div className={clsx(classes.th, classes.right)}>
          <TableSortLabel columnName={FutureShipmentSortBy.nBV} sorting={sorting}>
            {t('NBV')}
          </TableSortLabel>
        </div>
        <div className={clsx(classes.th, classes.last)} />
        {rows.map((row) => {
          return <TableItem key={row.quotaId} row={row} />;
        })}
      </div>
    </>
  );
};

const TableItem = ({ row }: { row: FutureShipmentViewModel }) => {
  const [show, setShow] = useState<boolean>(false);
  const classes = useStyles(show);

  const {
    expectedShipmentDate,
    quotaId,
    contractNumber,
    contractStatus,
    lesseeName,
    lesseeInn,
    dealerName,
    dealerInn,
    quotaManagerName,
    quotaManagerId,
    quotaManagerIsActive,
    issueAssigneeName,
    issueAssigneeId,
    issueAssigneeIsActive,
    issueId,
    nbv,
    controls,
    nomenclatures,
  } = row;

  const currentDate = dayjs();
  const shipmentDate = dayjs(expectedShipmentDate);
  const differenceInDays = shipmentDate.diff(currentDate, 'day');
  const dateClass = differenceInDays < 5 ? classes.orangeDate : '';
  const allHaveShipmentDateClass = nomenclatures.every((nomenclature) => nomenclature.shipmentDate)
    ? classes.allHaveShipmentDate
    : '';

  return (
    <>
      <div
        className={clsx(classes.rowWrapper, allHaveShipmentDateClass)}
        onClick={() => setShow((prev) => !prev)}
      >
        <div className={clsx(classes.contractNumber, classes.first, dateClass)}>
          {formatDate(expectedShipmentDate)}
        </div>
        <div className={clsx(classes.td, classes.right, classes.title)}>
          Договор:
          <br />
          Расчет:
          <br />
          ЛП:
          <br />
          Поставщик:
          <br />
          Менеджер:
          <br />
          Сопровождеждение:
          <br />
        </div>
        <div className={clsx(classes.td, classes.value)}>
          <Link className={classes.orangeLink} to={`/contracts?contractNumber=${contractNumber}`}>
            {contractNumber ? `№ ${contractNumber}` : ''}{' '}
            {contractStatus ? `| ${contractStatus}` : ''}
          </Link>
          <br />
          <Link className={classes.orangeLink} to={`/quotas/calculator/${quotaId}`}>
            № {quotaId}
          </Link>
          <br />
          <Link to={`/counterparties/${lesseeInn}`}>{lesseeName}</Link>
          <br />
          <Link to={`/counterparties/${dealerInn}`}>{dealerName}</Link>
          <br />
          <Link to={`/users/view/${quotaManagerId}`}>
            <User name={quotaManagerName} isActive={quotaManagerIsActive} />
          </Link>
          <br />
          <Link to={`/users/view/${issueAssigneeId}`}>
            <User name={issueAssigneeName ?? ''} isActive={issueAssigneeIsActive} />
          </Link>{' '}
          | <Link to={`/tasks/${issueId}`}>{issueId}</Link>
        </div>
        <div className={clsx(classes.td)}>
          <div className={clsx(classes.shipmentControls)}>
            {controls
              .filter((control) =>
                show ? true : control.color !== FutureShipmentControlColor.green
              )
              .map((control) => {
                return (
                  <>
                    {control.name === 'Скоринг одобрен' ? (
                      <>
                        <Link to={`/quotas/calculator/${quotaId}#approval`}>
                          <FutureShipmentControl {...control} key={control.name} />
                        </Link>
                      </>
                    ) : (
                      <FutureShipmentControl {...control} key={control.name} />
                    )}
                  </>
                );
              })}
          </div>
        </div>
        <div className={clsx(classes.td, classes.right)}>
          {nbv !== undefined ? formatMoney({ amount: nbv, currency: 'RUB' }) : ''}
        </div>
        <div className={clsx(classes.td, classes.center)}>
          <div className={classes.nomenclaturesSwitcher}>
            <IconSprite
              icon="switcher-list"
              width={12}
              color={show ? theme.palette.primary.main : theme.palette.text.secondary}
            />
          </div>
          <Link to={`/quotas/calculator/comments/${quotaId}?lessee=${lesseeName}&name=`}>
            <IconSprite icon="chat" width={14} color={theme.palette.text.secondary} />
          </Link>
          <br />
          <Link to={`/quotas/calculator/owner/${quotaId}`}>
            <IconSprite
              icon="menu-catalogs"
              width={14}
              height={16}
              color={theme.palette.text.secondary}
            />
          </Link>
        </div>

        {show && (
          <div className={clsx(classes.td, classes.nomenclatures)}>
            <FutureShipmentNomenclatureList nomenclatures={nomenclatures} />
          </div>
        )}
      </div>
    </>
  );
};
