import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Portal,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { CurrencyRatesInfo } from 'components/calculator/CalculationForm/CurrencyRatesInfo';
import { useTranslation } from 'react-i18next';
import { formatNumber } from 'components/utils/formatNumber';
import { formatCurrency } from 'components/utils/formatCurrency';
import {
  CalculationMethod,
  Currency,
  QuotaNomenclatureViewModel,
  RateType,
  TemplateKind,
} from 'schema/serverTypes';
import { CalculationResults } from 'components/calculator/CalculationForm/results/CalculationResults';
import { StraightLinePaymentSchedule } from 'components/calculator/CalculationForm/results/StraightLinePaymentSchedule';
import { AnnuityPaymentSchedule } from 'components/calculator/CalculationForm/results/AnnuityPaymentSchedule';
import { Button, ModalForm, Role, useModalForm } from 'components';
import { ApplicationRoles } from 'services/authentication/ApplicationRoles';
import { SaleContractFields } from './SaleContractFields';
import { CalculationFormProps } from './types';
import { FormProvider, useWatch } from 'react-hook-form';
import { LeaseContractFields } from './LeaseContractFields';
import { ComponentsFields } from './ComponentsFields';
import { PaymentScheduleAccordion } from './PaymentScheduleAccordion';
import { useCalculationSettingsQuery, useUserRole } from '../../../services';
import { PrintableTemplateList } from '../../templates/PrintableTemplatesList';
import { NomenclaturesRequestForm } from '../../calculator/CalculationForm/NomenclaturesRequestForm';
import { LeasingProductBrandsContextProvider } from './LeasingProductBrandsContextProvider';
import { useLeasingProductByNomenclature } from './useLeasingProductByNomenclature';
import { DataForQualificationsEditPanel as QualificationsEditPanel } from '../Qualification';
import clsx from 'clsx';
import { ScoringModelTable } from '../Approval/ScoringModelTable';
import { Send1CForm } from '../Send1CForm';
import { useSendTo1CHandler } from '../useSendTo1CHandler';
import { ScheduleToggle } from '../../calculator/CalculationForm/results/ScheduleToggle';
import { SeasonalPaymentSchedule } from '../../calculator/CalculationForm/results';
import { Schedule } from '../../calculator/CalculationForm/results/Schedule';
import { useSelectedLeasingProductsQuery } from './useSelectedProductQuery';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    panels: {
      marginBottom: (props: CalculationFormProps) => (props.isTask ? '0' : '75px'),
    },
    actions: {
      [theme.breakpoints.down(1200)]: {
        width: 'calc(100% - 85px)',
        left: 63,
      },
      display: 'flex',
      justifyContent: 'flex-start',
      width: 'calc(100% - 238px)',
      padding: theme.spacing('20px', '20px'),
      bottom: 0,
      left: 216,
      position: 'fixed',
      zIndex: 1,
    },
    actionsTask: {
      display: 'flex',
      justifyContent: 'flex-start',
      width: '100%',
      padding: theme.spacing(2.5, 2.5),
      bottom: 0,
      position: 'static',
      zIndex: 1,
    },
    actionButton: {
      marginRight: theme.spacing(2.5),
    },
    actionButtonRight: {
      marginLeft: 'auto',
      marginRight: 0,
    },
    warningIcon: {
      cursor: 'pointer',
      '&$warningIcon svg': {
        fill: theme.palette.darkAttention.main,
        cursor: 'pointer',
        pointerEvents: 'auto',
      },
    },
    link: {
      color: theme.palette.primary.main,
    },
    modalRoot: {
      textAlign: 'center',
    },
    modalTitle: {
      textAlign: 'center',
      marginBottom: theme.spacing(3),
    },
    modalText: {
      color: theme.palette.text.primary,
    },
    modalButton: {
      marginTop: theme.spacing(2),
      '& + &': {
        marginLeft: theme.spacing(2.5),
      },
    },
    errorRulesText: {
      fontWeight: 700,
      padding: theme.spacing(2, 0),
    },
    corridorErrors: {
      marginTop: theme.spacing(2),
      display: 'grid',
      gridTemplateColumns: '1fr 3fr',
      fontSize: 12,
      lineHeight: '16px',
      '& div': {
        borderBottom: '1px solid' + theme.palette.background.default,
        textAlign: 'left',
        padding: theme.spacing(1, 2),
      },
    },
    bigTitle: {
      '&&&': {
        minHeight: '42px',
      },
    },
    riskyScheduleLabel: {
      marginLeft: theme.spacing(3),
      color: theme.palette.warning.light,
    },
    forbiddenLabel: {
      marginLeft: theme.spacing(3),
      color: theme.palette.primary.main,
    },
  })
);

export const CalculationForm = forwardRef<HTMLDivElement, CalculationFormProps>((props, ref) => {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const { isSalesSupport, isInsideSales, isAdmin, isSalesManager, isSuperSalesManager } =
    useUserRole();

  const {
    calculationResult,
    isLoading,
    isTask = false,
    isLocked = false,
    quotaId,
    issueId,
    form,
    onSubmit,
    approvalErrorPopup,
    scoringIssueId,
    Popup1C,
  } = props;

  //DLLCRMV2-1481
  const [calculationMethodChange, setCalculationMethodChange] = useState<number>(0);
  const [isCofDisabled, setIsCofDisabled] = useState<boolean>(!!issueId);

  const isCopyEnabled = quotaId !== undefined;

  const { setValue, control, watch } = form;

  const { leasingProductByNomenclature, vendor } = useLeasingProductByNomenclature(control);

  const {
    onOpen: onOpenNomenclatures,
    onClose: onCloseNomenclatures,
    open: openNomenclatures,
  } = useModalForm();

  const {
    onOpen: onOpenLeasingProductWarning,
    onClose: onCloseLeasingProductWarning,
    open: openLeasingProductWarning,
  } = useModalForm();

  const { handleSendTo1C } = useSendTo1CHandler(quotaId ?? 0, Popup1C?.onClose1C);

  const [isOpenQualificationsPanel, setOpenQualificationsPanel] = useState<boolean>(false);

  const handleOnCalculate = useCallback(
    (event) => {
      setValue('save', false, { shouldValidate: true });
      onSubmit(event);
    },
    [setValue, onSubmit]
  );

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        handleOnCalculate(event);
      }
    },
    [handleOnCalculate]
  );

  const selectedLeaseProductName = watch('leaseProduct');
  const selectedLeaseProduct = useSelectedLeasingProductsQuery(selectedLeaseProductName);

  const handleOnSave = useCallback(
    (event) => {
      setValue('save', true, { shouldValidate: true });
      if (
        vendor !== '' &&
        leasingProductByNomenclature?.name &&
        leasingProductByNomenclature.name !== selectedLeaseProductName &&
        !selectedLeaseProduct?.isVendor
      ) {
        onOpenLeasingProductWarning();
      } else {
        onSubmit(event);
        setIsCofDisabled(true);
      }
    },
    [
      setValue,
      onSubmit,
      leasingProductByNomenclature?.name,
      vendor,
      onOpenLeasingProductWarning,
      selectedLeaseProduct?.isVendor,
      selectedLeaseProductName,
    ]
  );

  const { data: settings } = useCalculationSettingsQuery();
  const numberOfMonths = useWatch({ control, name: 'numberOfMonths' });
  const rateType = useWatch({ control, name: 'rateType' });

  const handleChangeLeaseProduct = useCallback(
    (event, changed: boolean) => {
      if (changed) {
        setValue('leaseProduct', leasingProductByNomenclature?.name, { shouldValidate: true });
        if (rateType === RateType.Floating) {
          setValue('cofPercents', settings?.cofFloatRate);
        } else if (
          leasingProductByNomenclature?.specialCof &&
          numberOfMonths &&
          numberOfMonths >= 24
        ) {
          setValue('cofPercents', leasingProductByNomenclature.specialCof);
        } else {
          setValue('cofPercents', settings?.cof);
        }
        onSubmit(event);
      } else {
        onSubmit(event);
      }
      onCloseLeasingProductWarning();
    },
    [
      onSubmit,
      setValue,
      onCloseLeasingProductWarning,
      leasingProductByNomenclature?.name,
      settings?.cof,
      settings?.cofFloatRate,
      leasingProductByNomenclature?.specialCof,
      numberOfMonths,
      rateType,
    ]
  );

  const handleOnCopy = useCallback(
    (event) => {
      if (isCopyEnabled) {
        setValue('copy', true, { shouldValidate: true });
        onSubmit(event);
      }
    },
    [isCopyEnabled, setValue, onSubmit]
  );

  const newNomenclatures = useMemo(() => {
    return props.nomenclatures
      ? props.nomenclatures?.filter((item: QuotaNomenclatureViewModel) => !item?.code)
      : [];
  }, [props.nomenclatures]);

  const isRiskySchedule = calculationResult?.isRiskySchedule ?? false;
  const isForbidden = calculationResult?.isForbidden ?? false;

  return (
    <FormProvider {...form}>
      <LeasingProductBrandsContextProvider>
        <form onSubmit={onSubmit} onKeyDown={handleKeyDown}>
          <div className={classes.panels}>
            <Accordion defaultExpanded={true}>
              <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                <Typography variant="subtitle1">{t('ContractOfSale')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <SaleContractFields
                  {...props}
                  quotaId={quotaId}
                  onOpenNomenclatures={onOpenNomenclatures}
                />
              </AccordionDetails>
            </Accordion>
            <CurrencyRatesInfo />
            <Accordion defaultExpanded={true}>
              <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
                <Typography variant="subtitle1">{t('ContractOfLease')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <LeaseContractFields
                  totalMargin={calculationResult?.totalMargin}
                  setCalculationMethodChange={setCalculationMethodChange}
                  isCofDisabled={isCofDisabled}
                  setIsCofDisabled={setIsCofDisabled}
                  scoringIssueId={scoringIssueId}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={true}>
              <AccordionSummary aria-controls="panel3a-content" id="panel3a-header">
                <Typography variant="subtitle1">{t('Components')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ComponentsFields telematics={calculationResult?.telematics} />
              </AccordionDetails>
            </Accordion>
            <PaymentScheduleAccordion
              quotaId={quotaId}
              calculationMethodChange={calculationMethodChange}
            />
            <Accordion disabled defaultExpanded={false} expanded={!!calculationResult}>
              <AccordionSummary ref={ref} aria-controls="panel4a-content" id="panel4a-header">
                <Typography variant="subtitle1">{t('CalculationResult')}</Typography>
                <Typography color="secondary" variant="body1">
                  &nbsp; NBV &nbsp;
                </Typography>
                <Typography color="secondary" variant="subtitle1">
                  {calculationResult?.fundingAmountNBV &&
                    formatNumber(calculationResult?.fundingAmountNBV, 2, true)}
                  &nbsp;
                  {calculationResult?.fundingAmountNBV && formatCurrency(Currency.Ruble)}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {calculationResult && <CalculationResults data={calculationResult} />}
              </AccordionDetails>
            </Accordion>
            <Accordion disabled defaultExpanded={false} expanded={!!calculationResult}>
              <AccordionSummary
                aria-controls="panel5a-content"
                id="panel5a-header"
                className={classes.bigTitle}
              >
                <Typography variant="subtitle1">{t('ScheduleOfPayments')}</Typography>
                {isRiskySchedule && (
                  <Typography className={classes.riskyScheduleLabel} variant="subtitle1">
                    Рискованный график
                  </Typography>
                )}
                {isForbidden && (
                  <Typography className={classes.forbiddenLabel} variant="subtitle1">
                    Запрещенный график
                  </Typography>
                )}
              </AccordionSummary>
              <AccordionDetails>
                {calculationResult?.calculationMethod === CalculationMethod.Annuity && (
                  <ScheduleToggle
                    OldComponent={AnnuityPaymentSchedule}
                    NewComponent={Schedule}
                    {...calculationResult}
                  />
                )}
                {calculationResult?.calculationMethod === CalculationMethod.StraightLine && (
                  <ScheduleToggle
                    OldComponent={StraightLinePaymentSchedule}
                    NewComponent={Schedule}
                    {...calculationResult}
                  />
                )}
                {calculationResult?.calculationMethod === CalculationMethod.Seasonal && (
                  <ScheduleToggle
                    OldComponent={SeasonalPaymentSchedule}
                    NewComponent={Schedule}
                    {...calculationResult}
                  />
                )}
                {calculationResult?.calculationMethod === CalculationMethod.SuperSeasonal && (
                  <ScheduleToggle
                    OldComponent={SeasonalPaymentSchedule}
                    NewComponent={Schedule}
                    {...calculationResult}
                  />
                )}
              </AccordionDetails>
            </Accordion>
            {quotaId && <PrintableTemplateList quotaId={quotaId} kind={TemplateKind.Calculation} />}
          </div>
          {isTask ? (
            <Paper square className={classes.actionsTask}>
              <div className={classes.actionButton}>
                <Button
                  color="primary"
                  size="medium"
                  type="submit"
                  variant="contained"
                  disabled={isLoading}
                  onClick={handleOnCalculate}
                >
                  {t('Calculate')}
                </Button>
              </div>
              <Role role={ApplicationRoles.allExceptAssetManager}>
                <div className={classes.actionButton}>
                  <Button
                    color="primary"
                    size="medium"
                    type="button"
                    variant="outlined"
                    disabled={isLoading || isLocked}
                    onClick={handleOnSave}
                  >
                    {t('Save')}
                  </Button>
                </div>
                {isCopyEnabled && (
                  <div className={classes.actionButton}>
                    <Button
                      color="primary"
                      size="medium"
                      type="button"
                      variant="outlined"
                      disabled={isLoading}
                      onClick={handleOnCopy}
                    >
                      {t('SaveAsNew')}
                    </Button>
                  </div>
                )}
              </Role>
              {quotaId && isAdmin && (
                <div className={clsx(classes.actionButton, classes.actionButtonRight)}>
                  <Button
                    color="primary"
                    size="medium"
                    type="button"
                    variant="outlined"
                    onClick={() => setOpenQualificationsPanel(true)}
                    disabled={isLoading}
                  >
                    {t('Qualify a deal')}
                  </Button>
                </div>
              )}
            </Paper>
          ) : (
            <Portal container={document.body}>
              <Paper square className={classes.actions}>
                <div className={classes.actionButton}>
                  <Button
                    color="primary"
                    size="medium"
                    type="submit"
                    variant="contained"
                    disabled={isLoading}
                    onClick={handleOnCalculate}
                  >
                    {t('Calculate')}
                  </Button>
                </div>
                <Role role={ApplicationRoles.allExceptAssetManager}>
                  <div className={classes.actionButton}>
                    <Button
                      color="primary"
                      size="medium"
                      type="button"
                      variant="outlined"
                      disabled={isLoading || isLocked}
                      onClick={handleOnSave}
                    >
                      {t('Save')}
                    </Button>
                  </div>
                  {isCopyEnabled && (
                    <div className={classes.actionButton}>
                      <Button
                        color="primary"
                        size="medium"
                        type="button"
                        variant="outlined"
                        disabled={isLoading}
                        onClick={handleOnCopy}
                      >
                        {t('SaveAsNew')}
                      </Button>
                    </div>
                  )}
                  {/*{quotaId && (*/}
                  {/*  <div className={classes.actionButton}>*/}
                  {/*    <Button*/}
                  {/*      color="primary"*/}
                  {/*      size="medium"*/}
                  {/*      type="button"*/}
                  {/*      variant="outlined"*/}
                  {/*      onClick={onOpenApprovalRequest}*/}
                  {/*      disabled={isLoading}*/}
                  {/*      className={newNomenclatures.length > 0 ? 'Mui-disabled' : ''}*/}
                  {/*      startIcon={*/}
                  {/*        newNomenclatures.length > 0 && (*/}
                  {/*          <WarningTooltip*/}
                  {/*            arrow*/}
                  {/*            placement="bottom-start"*/}
                  {/*            className={classes.warningIcon}*/}
                  {/*            onOpenNomenclatures={onOpenNomenclatures}*/}
                  {/*            taskIds={*/}
                  {/*              newNomenclatures.filter((item: any) => !item.taskId).length > 0*/}
                  {/*                ? []*/}
                  {/*                : newNomenclatures*/}
                  {/*                    .filter((item: any) => item.taskId)*/}
                  {/*                    .map((item: any) => item.taskId)*/}
                  {/*            }*/}
                  {/*          />*/}
                  {/*        )*/}
                  {/*      }*/}
                  {/*    >*/}
                  {/*      {t('Approve')}*/}
                  {/*    </Button>*/}
                  {/*  </div>*/}
                  {/*)}*/}
                </Role>
                {quotaId &&
                  ((isSalesSupport && issueId) ||
                    isInsideSales ||
                    isAdmin ||
                    isSalesManager ||
                    isSuperSalesManager) && (
                    <div className={clsx(classes.actionButton, classes.actionButtonRight)}>
                      <Button
                        color="primary"
                        size="medium"
                        type="button"
                        variant="outlined"
                        onClick={() => setOpenQualificationsPanel(true)}
                        disabled={isLoading}
                      >
                        {t('Qualify a deal')}
                      </Button>
                    </div>
                  )}
              </Paper>
            </Portal>
          )}
        </form>
        {isOpenQualificationsPanel && (
          <QualificationsEditPanel
            quotaId={quotaId}
            onClose={() => setOpenQualificationsPanel(false)}
          />
        )}
        {quotaId && newNomenclatures.length > 0 && (
          <ModalForm open={openNomenclatures} onClose={onCloseNomenclatures}>
            <NomenclaturesRequestForm
              newNomenclatures={newNomenclatures.filter((item: any) => !item.taskId)}
              quotaId={quotaId}
              onClose={onCloseNomenclatures}
            />
          </ModalForm>
        )}
        <ModalForm open={openLeasingProductWarning} onClose={onCloseLeasingProductWarning}>
          <div className={classes.modalRoot}>
            <Typography variant={'h2'} className={classes.modalTitle}>
              {t('Warning')}
            </Typography>
            <Typography variant="body2" className={classes.modalText}>
              {`Указанный бренд ${vendor} соотвествует каналу продаж ${leasingProductByNomenclature?.name}, изменить?`}
            </Typography>
            <div>
              <Button
                variant="contained"
                className={classes.modalButton}
                onClick={(event) => handleChangeLeaseProduct(event, true)}
              >
                {t('Yes')}
              </Button>
              <Button
                variant="outlined"
                className={classes.modalButton}
                onClick={(event) => handleChangeLeaseProduct(event, false)}
              >
                {t('No')}
              </Button>
            </div>
          </div>
        </ModalForm>
        {quotaId && (
          <>
            <ModalForm
              open={approvalErrorPopup?.openApprovalError}
              onClose={approvalErrorPopup?.onCloseApprovalError}
            >
              <div className={classes.modalRoot}>
                <Typography variant={'h2'} className={classes.modalTitle}>
                  Ошибка
                </Typography>
                <Typography variant="body2" className={classes.modalText}>
                  {`Изменения в расчёте, приводят к отмене выбранного кредитного решения.`}
                </Typography>
                {approvalErrorPopup &&
                  approvalErrorPopup.invalidScoringRules &&
                  approvalErrorPopup.invalidScoringRules.length > 0 && (
                    <div>
                      <Typography variant="body2" className={classes.errorRulesText} align={'left'}>
                        {`Отказ по ${approvalErrorPopup.invalidScoringRules.length} ${
                          approvalErrorPopup.invalidScoringRules.length % 10 === 1
                            ? 'правилу'
                            : 'правилам'
                        }`}
                      </Typography>
                      <ScoringModelTable
                        rows={approvalErrorPopup.invalidScoringRules}
                        type="errors"
                      />
                      <Typography variant="body2" className={classes.errorRulesText}>
                        Сохранение расчёта невозможно!
                      </Typography>
                    </div>
                  )}
                {approvalErrorPopup &&
                  approvalErrorPopup.corridorErrors &&
                  approvalErrorPopup.corridorErrors.length > 0 && (
                    <div>
                      <div className={classes.corridorErrors}>
                        {approvalErrorPopup.corridorErrors.map((error) => {
                          return (
                            <>
                              <div>{error.name}</div>
                              <div>{error.message}</div>
                            </>
                          );
                        })}
                      </div>
                      <Typography variant="body2" className={classes.errorRulesText}>
                        Сохранение расчёта невозможно!
                      </Typography>
                    </div>
                  )}
                <div>
                  <Button
                    variant="contained"
                    className={classes.modalButton}
                    onClick={approvalErrorPopup?.onCloseApprovalError}
                  >
                    Ок
                  </Button>
                </div>
              </div>
            </ModalForm>
            <ModalForm open={Popup1C?.open1C} onClose={Popup1C?.onClose1C}>
              <Send1CForm
                quotaId={quotaId ?? 0}
                handleSendTo1C={handleSendTo1C}
                onClose={Popup1C?.onClose1C}
              />
            </ModalForm>
          </>
        )}
      </LeasingProductBrandsContextProvider>
    </FormProvider>
  );
});
