import {
  createStyles,
  makeStyles,
  MenuItem,
  TextField as MuiTextField,
  Theme,
} from '@material-ui/core';
import { formatNumber, Grid, Select, Switch } from 'components';
import { AmountType, CalculationMethod, RateType, UserShortViewModel } from 'schema/serverTypes';
import { useTranslation } from 'react-i18next';
import { FixedAmountInput } from '../AmountInput';
import { useCalculationFormContext } from '../types';
import { ResidualValueInput } from './ResidualValueInput';
import { FundingAmountInput } from './FundingAmountInput';
import { GeneraleRateInput } from './GeneraleRateInput';
import { Dispatch, SetStateAction } from 'react';
import { PrepaymentAmountInput } from './PrepaymentAmountInput';
import { useCalculationSettingsQuery, useUsersBackendQuery1 } from '../../../../services';
import { CofPercents } from './CofPercents';
import { useLeasingProductQuery } from '../SaleContractFields/LeaseProductAutocomplete';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      width: '100%',
    },
    noMarginSwitch: {
      textAlign: 'right',
      '& .MuiGrid-root': {
        flexWrap: 'nowrap',
      },
      '& .MuiFormControlLabel-root': {
        marginRight: 0,
      },
    },
  })
);

type LeaseContractFieldsProps = {
  totalMargin?: number;
  setCalculationMethodChange: Dispatch<SetStateAction<number>>;
  isCofDisabled: boolean;
  setIsCofDisabled: Dispatch<SetStateAction<boolean>>;
  scoringIssueId?: number;
};

export const LeaseContractFields = ({
  totalMargin,
  setCalculationMethodChange,
  isCofDisabled,
  setIsCofDisabled,
  scoringIssueId,
}: LeaseContractFieldsProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { control, setValue, watch } = useCalculationFormContext();

  const { data: user } = useUsersBackendQuery1<UserShortViewModel>({
    relativeUrl: 'me',
    queryKey: 'me',
  });

  const { options = [] } = useLeasingProductQuery();
  const leaseProduct = watch('leaseProduct');
  const selectedLeaseProduct = options.find((t) => t.name === leaseProduct) ?? null;
  const { data: settings } = useCalculationSettingsQuery();

  const cofPercentsHandler = (value: number) => {
    if (selectedLeaseProduct?.specialCof && value && value >= 24) {
      setValue('cofPercents', selectedLeaseProduct.specialCof);
    } else {
      setValue('cofPercents', settings?.cof);
    }
  };

  const numberOfMonthsChangeHandler = (value: number) => {
    cofPercentsHandler(value);
  };

  const rateType = watch('rateType');

  const rateTypeChangeHandler = (value: RateType) => {
    if (value === RateType.Floating) {
      setValue('cofPercents', settings?.cofFloatRate);
    } else {
      const numberOfMonths = watch('numberOfMonths') ?? 0;
      cofPercentsHandler(numberOfMonths);
    }
  };

  return (
    <div className={classes.wrapper}>
      <Grid container columnSpacing={2} rowSpacing={2.5}>
        <Grid item md={4} xs={24}>
          <PrepaymentAmountInput />
        </Grid>
        <Grid item md={4} xs={24}>
          <FixedAmountInput
            name="tradeFee"
            label={`${t('Commission')}, %`}
            required
            allowZero
            amountMode={AmountType.Percents}
            max={10.0}
          />
        </Grid>
        <Grid item md={6} xs={24}>
          <Select
            control={control}
            name="numberOfMonths"
            label={t('LeaseTerm')}
            required
            lowercase
            onChangeCallback={numberOfMonthsChangeHandler}
          >
            {Array.from(Array(60 - 11)).map((_, i) => {
              return (
                <MenuItem key={i + 12} value={i + 12}>
                  {i + 12}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Grid item md={4} xs={24}>
          <FundingAmountInput />
        </Grid>
        <Grid item md={'auto'} xs={24}>
          <Switch
            name="hasResidualValue"
            label={t('RV')}
            labelOn={''}
            labelOff={''}
            type="checkbox"
            classes={{ root: classes.noMarginSwitch }}
          />
        </Grid>
        <Grid item md={5} xs={24}>
          <ResidualValueInput />
        </Grid>
        <Grid item md={6} xs={24}>
          <Select
            name="calculationMethod"
            label={t('ScheduleType')}
            required
            onChangeCallback={() => setCalculationMethodChange((prev) => prev + 1)}
          >
            <MenuItem value={CalculationMethod.Annuity}>
              {t('CalculationMethodType.Annuity')}
            </MenuItem>
            <MenuItem value={CalculationMethod.StraightLine}>
              {t('CalculationMethodType.StraightLine')}
            </MenuItem>
            <MenuItem value={CalculationMethod.Seasonal}>
              {t('CalculationMethodType.Seasonal')}
            </MenuItem>
            <MenuItem value={CalculationMethod.SuperSeasonal}>
              {t('CalculationMethodType.SuperSeasonal')}
            </MenuItem>
          </Select>
        </Grid>
        <Grid item md={3} xs={24}>
          <CofPercents
            setIsCofDisabled={setIsCofDisabled}
            isLockedButton={!scoringIssueId && rateType !== RateType.Floating && user?.canChangeCof}
            isCofDisabled={isCofDisabled}
          />
        </Grid>
        <Grid item md={3} xs={24}>
          <FixedAmountInput
            name="marginPercents"
            label={t('Margin')}
            amountMode={AmountType.Percents}
            fractionDigits={4}
            required
          />
        </Grid>
        <Grid item md={3} xs={24}>
          <MuiTextField
            name="totalMargin"
            label={t('TotalMargin')}
            value={formatNumber(totalMargin, 4) ?? ''}
            variant="outlined"
            size="small"
            fullWidth
            disabled
          />
        </Grid>
        <Grid item md={3} xs={24}>
          <GeneraleRateInput />
        </Grid>
        <Grid item md={6} xs={24}>
          <Select name="rateType" label={t('Rate type')} onChangeCallback={rateTypeChangeHandler}>
            <MenuItem value={RateType.Floating}>{t('RateType.Floating')}</MenuItem>
            <MenuItem value={RateType.ConditionallyFixed}>
              {t('RateType.ConditionallyFixed')}
            </MenuItem>
            <MenuItem value={RateType.Fixed}>{t('RateType.Fixed')}</MenuItem>
          </Select>
        </Grid>
      </Grid>
    </div>
  );
};
